import React from 'react';
import CustomCursor from './CustomCursor';
import Seo from './Seo';

interface Props {
  children: React.ReactNode;
}

function Layout({ children }: Props & any) {
  return (
    <>
      <Seo description={undefined} title={undefined} />
      <div>
        {children}
        <CustomCursor />
      </div>
    </>
  );
}

export default Layout;
