import React from 'react';

function CustomCursor() {
  const mainCursor = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const windowWidth = window.innerWidth;
    if (windowWidth > 460) {
      document.addEventListener(`mousemove`, (event) => {
        const { clientX, clientY } = event;
        let active = false;

        if (!active && mainCursor && mainCursor.current) {
          mainCursor.current.style.visibility = `visible`;
          // mainCursor.current.classList.add(`cursor-init`);
          active = true;
        }
        const mouseX = clientX;
        const mouseY = clientY;
        if (mainCursor && mainCursor.current) {
          // mainCursor.current.style.visibility = `visible`;
          mainCursor.current.style.transform = `translate3d(${
            mouseX - mainCursor.current.clientWidth / 2
          }px, ${mouseY - mainCursor.current.clientHeight / 2}px, 0)`;
        }
      });
      const a = document.querySelectorAll(`.cursor-pointer`);
      a.forEach((item) => {
        item.addEventListener(`mouseover`, () => {
          if (mainCursor && mainCursor.current) {
            mainCursor.current.classList.add(`cursor-active`);
          }
        });
        item.addEventListener(`mouseleave`, () => {
          if (mainCursor && mainCursor.current) {
            mainCursor.current.classList.remove(`cursor-active`);
          }
        });
      });
    }
  }, []);

  return (
    <div className="hidden lg:block cursor-wrapper">
      <div className="main-cursor" ref={mainCursor}>
        <div className="main-cursor-background" id="main-cursor" />
      </div>
    </div>
  );
}

export default CustomCursor;
