import React from 'react';
import { TransitionPortal } from 'gatsby-plugin-transition-link';

interface Props {
  children: React.ReactNode;
}

function TransitionLayout({ children }: Props) {
  return (
    <>
      {children}
      <TransitionPortal>
        <div className="box-ani" id="page-transition-overlay" />
      </TransitionPortal>
    </>
  );
}

export default TransitionLayout;
